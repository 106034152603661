import React from 'react'
import { css } from 'linaria'
import rhythm from '../../../utils/rhythm'
import { colors } from '../../../utils/theme'
import breakpoints from '../../../utils/tokens/breakpoints'
import hexToRgb from '../../../utils/hexToRgb'

const green = '#18b663'
const orange = '#fb8c00'
const red = '#e53935'

const gaugeSrc = css`
  fill: none;
  stroke: currentColor;
  stroke-width: 9;
  transition: stroke-dasharray 1.2s;
`

const gaugeBase = css`
  stroke: #e8eaed;
  stroke-width: 9;
`

const gauge = css`
  stroke-linecap: round;
`

const gaugePass = css`
  color: var(--color);
`

const gaugePercent = css`
  align-items: center;
  bottom: 0;
  color: currentColor;
  display: flex;
  font: inherit;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1rem;
`

const cardScore = css`
  width: 50px;
  height: 50px;
  contain: content;
  display: block;
  user-select: none;
  position: relative;
  margin: 0 auto ${rhythm(1 / 2)};
`

const card = css`
  font-size: 1rem;
  text-align: center;
  padding: ${rhythm(1)} ${rhythm(1 / 2)};

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md}) {
    &:nth-child(3),
    &:nth-child(4) {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
    }

    &:nth-child(2),
    &:nth-child(4) {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: ${rhythm(3 / 2)} ${rhythm(1 / 2)};
  }

  + div {
    @media (max-width: ${breakpoints.xs}) {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
    }

    @media (min-width: ${breakpoints.md}) {
      border-top: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`

const metric = css`
  display: flex;
  width: 100%;
  padding: ${rhythm(1 / 2)} ${rhythm(1)};
  margin: 0;
  font-size: 0.875rem;
  border-top: 1px solid rgba(${hexToRgb(colors.text.lightGray)}, 0.2);

  &:nth-child(1) {
    border-top: 0;
  }

  @media (min-width: ${breakpoints.sm}) {
    width: 50%;

    &:nth-child(2) {
      border-top: 0;
    }
  }

  &:nth-child(2n) {
    border-left: 1px solid rgba(${hexToRgb(colors.text.lightGray)}, 0.2);
  }
`

const valueSpan = css`
  margin-left: auto;
  color: var(--color);
`

const Metric = ({ value, title, unit = 's' }) => {
  let color = green

  if (value >= 3) {
    color = orange
  }

  if (value >= 6) {
    color = red
  }

  if (unit === 'ms') {
    if (value <= 150) {
      color = green
    }

    if (value <= 300) {
      color = orange
    } else {
      color = red
    }
  }

  return (
    <li className={metric}>
      <span>{title}</span>
      <span className={valueSpan} style={{ '--color': color }}>
        {value} {unit}
      </span>
    </li>
  )
}
const Card = ({ score = 0, name }) => {
  let color = red

  if (score >= 50) {
    color = orange
  }

  if (score >= 90) {
    color = green
  }

  return (
    <div className={card}>
      <div className={cardScore}>
        <div className={gaugePass} style={{ '--color': color }}>
          <svg
            viewBox="0 0 120 120"
            className={gauge}
            fill="none"
            strokeWidth="2"
          >
            <circle className={gaugeBase} r="53" cx="60" cy="60" />
            <circle
              className={gaugeSrc}
              transform="rotate(-90 60 60)"
              strokeDasharray={`${(score * 329) / 100} 329`}
              strokeDashoffset="0"
              r="53"
              cx="60"
              cy="60"
            />
          </svg>
          <div class={gaugePercent}>{score}</div>
        </div>
      </div>

      {name}
    </div>
  )
}

const lighthouseBoard = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: ${colors.background.lightGray};
  border-radius: 28px;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const container = css`
  margin: ${rhythm(3 / 2)} 0;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    margin-left: -${rhythm(2)};
    margin-right: -${rhythm(2)};
  }
`

const link = css`
  font-size: 0.75rem;
  text-align: right;
  margin: ${rhythm(1 / 2)} ${rhythm(2)} 0 0;
`

const softLink = css`
  color: ${colors.text.lightGray};
  border: 0;

  &:hover {
    color: ${colors.accent.purple};
  }
`

const metrics = css`
  list-style: none;
  padding: 28px 0 0;
  margin: -29px 0 0;
  display: flex;
  border: 1px solid rgba(${hexToRgb(colors.text.lightGray)}, 0.2);
  border-radius: 0 0 28px 28px;
  flex-wrap: wrap;
`

const Lighthouse = ({
  performance = 0,
  accessibility = 0,
  bestpractices = 0,
  seo = 0,
  report,
  fcp,
  fmp,
  si,
  fci,
  tti,
  fid
}) => {
  let shouldRenderMetrics = fcp || fmp || si || fci || tti || fid

  return (
    <div className={container}>
      <div className={lighthouseBoard}>
        <Card score={performance} name="Performance" />
        <Card score={accessibility} name="Accessibility" />
        <Card score={bestpractices} name="Best Practices" />
        <Card score={seo} name="SEO" />
      </div>

      {shouldRenderMetrics && (
        <div>
          <ul className={metrics}>
            {fcp && <Metric value={fcp} title="First Contentful Paint" />}
            {fmp && <Metric value={fmp} title="First Meaningful Paint" />}
            {si && <Metric value={si} title="Speed Index" />}
            {fci && <Metric value={fci} title="First CPU Idle" />}
            {tti && <Metric value={tti} title="Time to Interactive" />}
            {fid && (
              <Metric
                value={fid}
                title="Max Potential First Input Delay"
                unit="ms"
              />
            )}
          </ul>
        </div>
      )}

      {report && (
        <p className={link}>
          <a
            href={report}
            className={softLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            View full report
          </a>
        </p>
      )}
    </div>
  )
}

export default Lighthouse
